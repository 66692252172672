import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import logo from "../../images/logo.png";
import logoLight from "../../images/logoLight.png";
import BGDark from "../../images/BG.jpg";
import BGLite from "../../images/BGLight.png";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../Actions/LoginAction";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Paper,
} from "@mui/material";
import { noty } from "../../services/notyService";

let loginBackGround = {
  // width: '100%',
};

let boxBackground = {
  // background: '#272528',
};

let loginStyle = {
  display: "flex",
  marginLeft: "auto",
};

let loginWrapper = {
  height: "50vh",
};

const servers = {
  UAT: "https://hcmuat.evonsys.com.au/prweb/api/atsapi/v1/",
  Dev: "https://hcmdev.evonsys.com.au/prweb/api/atsapi/v1/",
  Client: "https://client1.peoplehcm.com/prweb/api/atsapi/v1/",
};

const Login = ({ matches, mode }) => {
  let dispatch = useDispatch();
  const loginLoader =
    useSelector((state) => state.LoginReducer.loginLoader) || false;

  let [userDetails, setUserDetails] = useState({
    EmailID: "",
    PassCode: "",
    Environment: "Dev",
  });

  const updateUserDetails = (e) => {
    return setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const validateUser = () => {
    if (
      !userDetails.EmailID ||
      !userDetails.PassCode === "" ||
      !userDetails.Environment === ""
    ) {
      noty("error", "Please enter value");
    } else {
      dispatch(
        LoginAction.validateUser({
          EmailID: userDetails.EmailID,
          PassCode: userDetails.PassCode,
        })
      );
      dispatch(LoginAction.loginLoader());
    }
  };

  useEffect(() => {
    localStorage.setItem("Environment", servers[userDetails.Environment]);
    dispatch(LoginAction.getTimezonesLoader());
    dispatch(LoginAction.getTimezones());
  }, [dispatch, userDetails.Environment]);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(80,80,80,.4), rgba(80,80,80,.4)), url(${
          mode === "light" ? BGLite : BGDark
        })`,
        backgroundPosition: "unset",
        backgroundSize: "cover",
        padding: "20px",
        height: "95vh",
      }}
    >
      <Grid container justifyContent={matches ? "center" : "flex-start"}>
        {!matches && <Grid item xs={0} md={0} lg={7}></Grid>}
        <Grid item xs={matches ? 6 : 2} md={matches ? 6 : 4} alignSelf="center">
          <Box mt={3} mb={2}>
            <img
              src={mode === "light" ? logoLight : logo}
              style={{ width: matches ? "150px" : "300px" }}
              alt="company logo"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={matches ? "center" : "space-around"}
        alignItems="center"
        style={loginWrapper}
      >
        {!matches && (
          <Grid item sm={0} md={3}>
            <Box mt={4}> &nbsp;</Box>
          </Grid>
        )}

        <Grid item xs={10} md={3} alignSelf="center">
          <Paper>
            <Box p={4} boxShadow={2} style={boxBackground}>
              <Typography variant="h5">Login</Typography>
              <Box mb={2}>
                <Autocomplete
                  value={userDetails.Environment}
                  fullWidth
                  onChange={(event, newValue) => {
                    return setUserDetails({
                      ...userDetails,
                      Environment: newValue,
                    });
                  }}
                  id="controllable-states-demo"
                  options={Object.keys(servers)?.map((ele) => ele) || []}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Select Environment *"
                      variant="standard"
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email *"
                  onChange={updateUserDetails}
                  name="EmailID"
                  value={userDetails.email}
                  variant="standard"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  type="password"
                  fullWidth
                  id="password"
                  label="Password *"
                  onChange={updateUserDetails}
                  name="PassCode"
                  value={userDetails.password}
                  variant="standard"
                />
              </Box>

              <LoadingButton
                style={loginStyle}
                disabled={loginLoader}
                color="primary"
                onClick={validateUser}
                loading={loginLoader}
                variant="contained"
              >
                Login
              </LoadingButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
